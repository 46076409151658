import { Linking, Platform } from 'react-native';

export const SCHEME_URL_PATTERN = /.+:\/\//i;
export const onUrlPress = async (url: string) => {
  try {
    // To handle url without a scheme.
    if (SCHEME_URL_PATTERN.test(url)) {
      await Linking.openURL(url);
    } else {
      await Linking.openURL(`https://${url}`);
    }
  } catch (error) {
    console.error(`No handler for URL: ${url}`, error);
  }
};

const isWeb = Platform.OS === 'web';

export const onPhonePress = async (phone: string) => {
  try {
    if (!isWeb) {
      await Linking.openURL(`tel:${phone}`);
    }
  } catch (error) {
    console.error(`No handler for telephone: ${phone}`, error);
  }
};

export const onEmailPress = async (email: string) => {
  try {
    await Linking.openURL(`mailto:${email}`);
  } catch (error) {
    console.error(`No handler for text: ${email}`, error);
  }
};
